import React, { useState, useEffect } from 'react';
import { StakingAbi, StakingAddress } from '../components/helper/stakingContract';

export default function Admin({ active, account, library, connector, activate, deactivate, calc, logeado, conectar }) {
  const [inputstake, setInputstake] = useState('');
  const [inputxcent, setInputxcent] = useState('');
  const [inputDesposito, setInputDesposito] = useState('');
  const [textareastake, setTextareastake] = useState('');
  const [load, setLoad] = useState(0);
  const [hash, setHash] = useState(0);
  const [balance, setBalance] = useState(0);
  const [wallets, setWallet] = useState(0);

  //////////////////LOADING STAKING/////////////////////////////
  const desposito = async () => {
    if (!inputDesposito) {
      alert("Necesitas Agregar un monto");
    }
    if (account) {
      let convertToWei = inputDesposito * 1000000000;
      setLoad(1)
      const evuStake = new library.eth.Contract(
        StakingAbi,
        StakingAddress
      );
      await evuStake.methods.deposit(convertToWei)
        .send({ from: account })
        .on('receipt', (receipt) => {
          console.log(receipt.transactionHash)
          setHash(receipt.transactionHash)
          setLoad(0)
        }).on('error', function (error) {
          setLoad(0)
          console.log(error.message);
        });
    }
  }
  ////////////DEPOSITO STAKING///////

  /////////////setWalletxCent APY////////////
  const walletxcent = async () => {
    if (!inputstake) {
      alert("Necesitas Agregar matriz inicio y final [0,2]");
    }
    if (account) {
      setLoad(1)
      const evuStake = new library.eth.Contract(
        StakingAbi,
        StakingAddress
      );
      await evuStake.methods.setWalletxCent(inputxcent)
        .send({ from: account })
        .on('receipt', (receipt) => {
          setHash(receipt)
          setLoad(0)
        }).on('error', function (error) {
          setLoad(0)
          console.log(error.message);
        });
    }
  }

  /////////////CHANGE APY////////////
  const changeApy = async () => {
    if (!inputstake) {
      alert("Necesitas Agregar un porcentage");
    }
    if (account) {
      setLoad(1)
      const evuStake = new library.eth.Contract(
        StakingAbi,
        StakingAddress
      );
      await evuStake.methods.UpdatePercentRewards(inputstake)
        .send({ from: account })
        .on('receipt', (receipt) => {
          setHash(receipt)
          setLoad(0)
        }).on('error', function (error) {
          setLoad(0)
          console.log(error.message);
        });
    }
  }
  /////////////CHANGE APY////////////
  const AddBonosIco = () => {
    if (!textareastake) {
      alert("Necesitas Agregar las wallet y el bono!");
    }
    if (account) {
      const evuStake = new library.eth.Contract(
        StakingAbi,
        StakingAddress
      );
      setLoad(1)
      let multiBonos = textareastake;
      let multiWallet = multiBonos.split("\n"); // arrayOfLines is array where every 
      const wallet = [];
      const bonos = [];
      for (let i = 0; i < multiWallet.length; i++) {
        let arr = multiWallet[i].split(",")
        wallet.push(arr[0]);
        bonos.push(arr[1]);
      }
      evuStake.methods.AddBonos(wallet, bonos)
        .send({ from: account })
        .on('receipt', (receipt) => {

          setHash(receipt)
          setLoad(0)
        }).on('error', function (error) {
          setLoad(0)
          console.log(error.message);
        });
    }
  }

  //////GET VALUE INPUT wallet xcent//////
  const handleInputWalletXcent = (event) => {
    event.preventDefault();
    setInputxcent(event.target.value)
  }
  //////GET VALUE INPUT deposito//////
  const handleInputDeposito = (event) => {
    event.preventDefault();
    setInputDesposito(event.target.value)
  }
  //////GET VALUE INPUT//////
  const handleInputChange = (event) => {
    event.preventDefault();
    setInputstake(event.target.value)
  }
  //////GET VALUE TEXTAREA//////
  const handletextChange = (event) => {
    event.preventDefault();
    console.log(event.target.value)
    setTextareastake(event.target.value)
  }
  const loader = async () => {
    if (account) {
      const evuStake = new library.eth.Contract(
        StakingAbi,
        StakingAddress
      );
      let balances = await evuStake.methods._balances().call();
      setBalance((balances / 1000000000).toFixed(2))

      let wallets = await evuStake.methods.CountWallet().call();
      setWallet((wallets))
    }

  }

  useEffect(() => {

    loader()
  })
  useEffect(() => {
    document.title = "Staking Admin";
  }, [])




  return (
    <main>
      <section className="staking-progresivo">
        <div className="container">
          <h1>Balance Disponible: {balance}</h1>
          <h2>Cantidad de wallets: {wallets}</h2>
          <div className='row'>
            <div className='col-md-12 pb-3'>
              {load ? (
                <div className="spinner-border spinner-border-lg text-white" >
                  <span className="visually-hidden">Loading...</span>
                </div>) : (null)}

              {hash ? (<input className="form-control" value={hash} />) : (null)}
            </div>


            <div className="data-value border-warning col-md-12">
              <input className='p-1'
                type='number'
                value={inputstake}
                onChange={handleInputChange} />

              <button className='btn btn-info' onClick={changeApy}>ChangeAPY</button>

            </div>

            <div className="data-value border-warning col-md-12">
              <input className='p-1'
                type='number'
                value={inputDesposito}
                onChange={handleInputDeposito} />

              <button className='btn btn-info' onClick={desposito}>Desposito</button>

            </div>

            <div className='row mt-5'>
              <div className='col-md-3'></div>
             
                <div className="data-value border-warning col-md-4">
                  <input className='p-1'
                    value={inputxcent}
                    onChange={handleInputWalletXcent} />
                  <button className='btn btn-info' onClick={walletxcent}>walletxcent</button>
                </div>
                <div className='col-md-4'></div>
           
            </div>

            <div className='row mt-5'>
              <div className='col-md-4'></div>
              <div className="col-md-4">
                <div class="mb-3">
                  <label for="exampleFormControlTextarea1" className="form-label text-white">Listar Wallet - Bonos</label>
                  <textarea className="form-control border-warning form-control-lg" rows="8" onChange={handletextChange}>{textareastake}</textarea>
                </div>
                <button className='btn btn-info' onClick={AddBonosIco}>Add bonos o bloquear</button>

              </div>
              <div className='col-md-4'></div>
            </div>
          </div>
        </div>

      </section>

    </main>
  )

}
