import React from 'react'
import {useTranslation} from "react-i18next"
export default function PanelButton({checkaprove,mybalance,mystake,stakestado,stakeStart,unStaking,reStaking,ClaimReward,approveStart,unstake,myreward}) {

  const[t,i18n] = useTranslation("global");
  console.log('botones'+unstake);
  return (
    <>
             {checkaprove > 0 ? (
                      <div class="panel__stake d-flex">
                            {mybalance > 0 ?
                            (<>{stakestado == 1 ? (<><button className="btn-evulus-medium" type="button" disabled>
                             <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Stake</button></>):(<button className="btn-evulus-medium" type="button" onClick={stakeStart}>Stake</button>)}
                            </>):(<input className="btn-secondary"  type="button"  value="Stake" disabled/>)
                            }
                             {mystake[0] > 0 ?
                             (
                             <>{stakestado == 2 ? (<><button className="btn-evulus-medium" type="button" disabled>
                             <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>unStake</button></>):(<>{unstake && myreward < 1000000000 ? (<button className="btn-evulus-medium" type="button" onClick={unStaking}>unStake</button>):(<button className="btn-evulus-medium bg-secondary"  type="button" disabled>unStake</button>)}</>)}
                             
                               {stakestado == 4 ? (<><button className="btn-evulus-medium" type="button" disabled>
                             <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{t("Staked.claim")} EVU</button></>):(<button className="btn-evulus-medium" type="button" onClick={ClaimReward}>{t("Staked.claim")+" EVU"}</button>)}
                             </>
                            ):(<>
                          <button className="btn-evulus-medium bg-secondary"  type="button"  disabled>unStake</button>
                                                      
                            <button className="btn-evulus-medium bg-secondary"  type="button"  disabled >{t("Staked.claim")+" EVU"}</button></>)}
                        </div>
                        ):(
                      <div class="panel__stake d-flex">
                         {stakestado == 5 ? (<><button className="btn-evulus-medium" type="button" disabled>
                             <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Approve</button></>):(<button className="btn-evulus-medium" type="button" onClick={approveStart}>Aprrove</button>)}
                      </div>)}  

               </>
  )
}
