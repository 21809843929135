import { BscConnector } from '@binance-chain/bsc-connector'
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
//import { WalletLinkConnector } from '@web3-react/walletlink-connector';

const RPC_URLS = {
	1: 'https://speedy-nodes-nyc.moralis.io/1d2deeedbfdfdf73f5f08e9f/bsc/mainnet',
	3: 'https://ropsten.etherscan.io',
};
//BSC
export  const bcsscan = new BscConnector({
  supportedChainIds: [3] // later on 1 ethereum mainnet and 3 ethereum ropsten will be supported
})
//metamask
export const injected = new InjectedConnector({
	supportedChainIds: [3]
});


export const walletconnect = new WalletConnectConnector({
	rpc: {
		1: RPC_URLS[3]
	},
	qrcode: true,
	pollingInterval: 15000
});


export function resetWalletConnector(connector) {
	if (connector && connector instanceof WalletConnectConnector) {
		connector.walletConnectProvider = undefined;
	}
}

//coinbase
/*export const walletlink = new WalletLinkConnector({
	url: RPC_URLS[4],
	appName: 'demo-app',
	supportedChainIds: [ 1, 4 ]
});*/