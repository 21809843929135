import React from 'react'
import Probit from '../helper/Probit';
import NumberFormat from 'react-number-format';
import {useTranslation} from "react-i18next"
export default function TitleStake({account,evuapy,TVL,logeado}) {
    const[t] = useTranslation("global");
    const [evuprice, setEvuprice] = React.useState(0);
     Probit().then((web) =>{
        setEvuprice(web);
       })
   
    return (<>
               <div className="title">
                    <h1 className="titulo">{t("title.Progressive Staking")}</h1>
                    <h5 className="code">{account}</h5>
                </div>

                <div className="precios">
                    <div className="apy container-btn-date">
                        <div className="btn-date">
                            <div className="precios__title">APY</div>
                            <span>{evuapy}%</span>
                        </div>

                    </div>
                    <div className="tvl  container-btn-date">
                        <div className="btn-date">
                            <div className="precios__title">TVL</div>
                            <span><NumberFormat value={TVL} displayType={'text'} thousandSeparator={true}/></span>
                        </div>
                    </div>
                    <div className="precio-evu  container-btn-date">
                        <div className="btn-date">

                            <div className="precios__title">{t("title.Price EVU")}</div>
                            <span>{evuprice}</span>
                        </div>
                    </div>
                </div>
                </>);
}
