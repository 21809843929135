import React from 'react'
import {useTranslation} from "react-i18next"
export default function Staking() {
    const[t,i18n] = useTranslation("global");
  return (
    <section className="staking">
            <div className="container">
                <div className="row">
                    <div className="staking-content col-lg-9 mt-4">
                            <div className="staking-content__title text-start">
                                <h1 style={{ textTransform: 'uppercase'}}><b>{t("title.Progressive Staking")}</b></h1>
                                <h2>{t("home.Earn up to a")}</h2>
                            </div>
                            <div>
                                <a href="#" className="btn-evulus"> 250%</a>
                            </div>
                            </div>
                        <div class="staking__img col-lg-3 wow animate__animated animate__fadeInUp">
                            <img src="assets/img/staking2.png" alt="evulus staking"/>
                        </div>
                        <div class="d-flex flex-wrap row wow animate__animated animate__fadeInUp">
                            <div className="staking-content__parrafo col-lg-4 col-12">
                                <h4>{t("home.What is Staking?")}</h4>
                                <p className='text-start'>{t("home.Respuesta Staking")} <a href=""><span className='text-warning'>{t("fooder.more")}</span></a></p>
                             
                            </div>
                            <div class="staking-content__parrafo col-lg-4 col-sm-6 col-12 ">
                            <h4>{t("title.Progressive Staking")}</h4>
                            <p className='text-start'>{t("header.Progressive Staking p1")} <a href="https://blog.evulus.com/novedoso-staking-progresivo-evulus-evu/"><span className='text-warning' >{t("fooder.more")}</span></a> </p>
                        </div>
                            <div className="staking-content__parrafo col-lg-4 col-12 ">
                                <h4>{t("home.Affiliate plan")}</h4>
                                <p className='text-start'>{t("home.Repuesta Afiliados")} <a href="https://blog.evulus.com/evulus-staking-gran-rentabilidad-capital-invertido-bono-referido/"><span className='text-warning' >{t("fooder.more")}</span></a></p>
                              
                            </div>
                           
                        </div>
            
                    <div className="d-flex">
                 
                        <button className="btn-green" aria-current="page" data-bs-toggle="modal">{t("home.Do you want to know your benefits according to your investment?")}</button>
                        <a href="https://github.com/Tech-Audit/Smart-Contract-Audits/blob/main/TECHAUDIT_EVULUS%20STAKING.pdf" target="_blank" className="btn-green bg-warning">
                            <i className="far fa-eye"></i>Ver Auditoria</a>
                    </div>
                </div>
            </div>
    </section>    
  )
}
