import React, {useState,useEffect} from 'react';
import {contractAbi, contractAddress} from '../components/helper/contract';
import {StakingAbi, StakingAddress} from '../components/helper/stakingContract';
import FMnunber from '../components/helper/helper';
import TitleStake from '../components/stake/TitleStake';
import PanelMes from '../components/stake/PanelMes';
import PanelBalance from '../components/stake/PanelBalance';
import PanelTable from '../components/stake/PanelTable';
import PanelButton from '../components/stake/PanelButton';
import {useTranslation} from "react-i18next"



export default function Staking({active, account, library, connector, activate, deactivate,calc,logeado,conectar,redbsc}) {
     const[t] = useTranslation("global");
     const [testTokenContract, setTestTokenContract] = useState('');
     const [EvuStakingContract, setEvuStakingContract] = useState('');
     const [inputstake, setInputstake] = useState('');
     const [evuapy, setEvuapy] = useState('');
     const [checkaprove, setcheckaprove] = useState(0);
     const [mybalance, setMybalance] = useState(0);
     const [countacount, setCountacount] = useState('');
     const [listacount, setListacount] = useState([]);
     const [listrewards, setListrewards] = useState([]);
     const [slidervalue, setSlidervalue] = useState(30);
     const [selectaccount, setSelectaccount] = useState(0);
     const [totaldeposito, setTotaldeposito] = useState(0);
     const [myreward, setMyreward] = useState(0);
     const [mystake, setMystake] = useState(0); 
     const [stakestado, setStakestado] = useState(0); 
     const [selecionar, setSelecionar] = useState(0); 
     const [unstake, setUnstake] = useState(false); 
     const [totalRewards, setTotalRewards] = useState(false); 

  


  const fetchDataFromBlockchain = async ()=>{
  
//////////////////LOADING TOKEN/////////////////////////////
  const testToken = new library.eth.Contract(
        contractAbi,
        contractAddress
       );
      
      
     
    if(testToken){
    setTestTokenContract(testToken);
    ///MY TOKEN BALANCE /////
     let tokenBalance = await testToken.methods
     .balanceOf(account).call();
     setMybalance(tokenBalance)

    let aprove = await testToken.methods
     .allowance(account, StakingAddress).call();
     setcheckaprove(aprove)
   }
//////////////////LOADING STAKING/////////////////////////////
      const evuStake = new library.eth.Contract(
        StakingAbi,
        StakingAddress
      );
    
    if (evuStake) {
        
        setEvuStakingContract(evuStake);
        let ShowPercent = await evuStake.methods.ShowPercent().call();
        setEvuapy(ShowPercent)
        let SupplyStaking = await evuStake.methods._totalSupply().call();
        setTotaldeposito(SupplyStaking)
        let getCount =  await evuStake.methods.getCount(account).call();

        setCountacount(getCount);

        if(countacount > 0){
        
              await listarAccount(countacount);
              
              if(selectaccount >= 0){
                  let gstake =  await evuStake.methods.getAccounts(account,selectaccount).call();
                  if(gstake[3] < (Date.now() / 1000)){
                      setUnstake(true)
                  }else{
                    setUnstake(false)
                  }

                  setMystake(gstake[0])
                const greward = await  evuStake.methods.getTotalRewards(account,selectaccount).call();
              //  console.log(greward)
                  setMyreward(greward)
            }    
       }   
    }
   
}

/////////////APROBAR WALLET////////////
  const approveStart = () =>{
    if (account) {
        let convertToWei = 1000000 * 1000000000;
          setStakestado(5)
        testTokenContract.methods
          .approve(StakingAddress, convertToWei.toLocaleString('fullwide', {useGrouping:false}) )
          .send({ from: account })
          .on('receipt', (receipt) => {
            fetchDataFromBlockchain();
            setStakestado(0)
          }).on('error', function(error) {
            fetchDataFromBlockchain();
            console.log('Error Code:', error.code);
            console.log(error.message);
            setStakestado(0)
          });
      }
  }
///////////EMPIEZA LA ACCION DEL STAKING////////
    const stakeStart = () => {
      if (!account) {
      } else {
        if (!inputstake || inputstake === '0' || inputstake < 0) {

          document.getElementById("myAnchor").focus();
          setInputstake('');
        } else {
          setStakestado(1)
          let convertToWei = inputstake * 1000000000;

          console.log( convertToWei.toLocaleString('fullwide', {useGrouping:false}) ); 
                EvuStakingContract.methods
                  .stake(convertToWei.toLocaleString('fullwide', {useGrouping:false}),slidervalue)
                  .send({ from: account })
                  .on('transactionHash', (hash) => {
                    fetchDataFromBlockchain();
                  })
                  .on('receipt', (receipt) => {
                    fetchDataFromBlockchain();
                     setStakestado(0)
                  })
                  .on('confirmation', (confirmationNumber, receipt) => {
                    fetchDataFromBlockchain();
                  }).on('error', function(error) {
            console.log('Error Code:', error.code);
            console.log(error.message);
            setStakestado(0)
        
          });
            setInputstake('');
        }
      }
    };
  //////GET VALUE INPUT//////
  const handleInputChange =(event) =>{
    event.preventDefault();
    console.log(event.target.value*1000000000)
    setInputstake(event.target.value)
  }
  /////LISTAR WALLET STAKING/////////
  const listarAccount = async(total) =>{
    let d =[];
    let sum = 0;
    let selecccion = 0;
    if(listacount.length < total){
        for (let i = 0; i < total; i++) {
          d.push(await EvuStakingContract.methods
         .getAccounts(account,i).call());  
        }
        for (let i = 0; i < d.length; i++) {
          sum += new Number(d[i][0])
          if(d[i][0] > 0){
            selecccion = i;
          }
         }
         setSelectaccount(selecccion)
         setTotalRewards(sum)
         setSelecionar(selecccion)
         await setListacount(d)
        
    }
  }
  ////////////TOTAL GANANCIAS////////
 const ClaimReward = async (e)=>{
   // let index = e.target.attributes.getNamedItem("data-gid").value;
   setStakestado(4)
    EvuStakingContract.methods
  .ClaimReward(selectaccount) 
  .send({ from: account }).on('transactionHash', (hash) => {
    setMyreward(0)
   // console.log(hash)
    })
  .on('receipt', (receipt) => {
    fetchDataFromBlockchain();
    setStakestado(0)
    setMyreward(0)
  })
  .on('error', function(error) {
    setStakestado(0)
   // console.log('Error Code:', error.code);
    //console.log(error.message);
  });
  }
  /////REMOVER ITEM DEL STARKING
  const removeItem = (id)=>{
    const newList = listacount;
    const newList2 = listrewards;
    newList.splice(id,1)
    newList2.splice(id,1)
    setListrewards(newList2)
    setListacount(newList)
  }
////////INICIAL UNSTAKING////////
const unStaking = async () =>{
  
  let index = selectaccount; 
  setStakestado(2)
  console.log(index);
  EvuStakingContract.methods
  .unStaking(index) 
  .send({ from: account }).on('transactionHash', (hash) => {

  })
  .on('receipt', (receipt) => {
    removeItem(index);
    if(countacount > 0){
      listarAccount(countacount);
    }
    setStakestado(0)
    fetchDataFromBlockchain();
  })
  .on('confirmation', (confirmationNumber, receipt) => {
   /// console.log(confirmationNumber)
 
  }).on('error', function(error) {
    console.log('Error Code:', error.code);
    console.log(error.message);
    setStakestado(0)
  });
  setInputstake('');
 }
//////////////Max Balance////////////////////
const maxBalance = () =>{
  setInputstake(FMnunber(mybalance))

}
  ////////INICIAL UNSTAKING////////
const reStaking = async () =>{
  setStakestado(3)
  EvuStakingContract.methods
  .restaking(slidervalue,selectaccount) 
  .send({ from: account })
  .on('receipt', (receipt) => {
    fetchDataFromBlockchain();
    setStakestado(0)
  })
  .on('confirmation', (confirmationNumber, receipt) => {
    console.log(confirmationNumber)
  }).on('error', function(error) {
    setStakestado(0)
    console.log('Error Code:', error.code);
    console.log(error.message);
  });
  setInputstake('');
 }

    const handleChange = (event) => {
       event.preventDefault();
      setSelectaccount(event.target.value);
    };

  const nextb = () =>{
    if(slidervalue < 360 ){
      setSlidervalue(slidervalue + 1)
   }else{
    setSlidervalue(360)
   }
  }
  const prevb = () =>{
   if(slidervalue > 14 ){
      setSlidervalue(slidervalue - 1)
   }else{
    setSlidervalue(14)
   }
  }

  //////////////LOADER CONTENIDO////////////////////
   useEffect(()=> {

    document.title = "Staking Progresivo";
    
    const loader = async()=> {
      if (localStorage?.getItem('isWalletConnected') === 'true') {
          try {
            await fetchDataFromBlockchain();
          } catch (ex) {
          }
       }else{
         console.log("No login")
       }    
    }

    loader()
  }, [
      active,
      account,
      evuapy,
      mybalance,
      inputstake,
      countacount,
      listrewards,
      selectaccount,
      myreward,
      slidervalue,
      logeado,
      unstake,
      checkaprove
  ])

  const myStyle = {

    fontSize: "10px",
    maxWidth:"60px",
    lineHeight:"15px"
  };

 
    return (
      
      <main>
        <section className="staking-progresivo">
            <div className="container">
              <div className='image text-end p-0'>
                  <a href="#"><img  className='text-end'  src="../../assets/img/boton1.svg" width={200}/></a>
                  </div>
              <TitleStake account={account} evuapy={evuapy} TVL={(FMnunber(totaldeposito))} logeado={logeado}/>
              {!account || evuapy < 10 ?(<>
                
                {redbsc ?(<><div className="alert alert-danger panel__stake" role="alert">
                  <h3 className='text-danger'>{t("title.Error: Wrong Network please connect to BSC")}</h3></div><br/></>):(null)}
              <button type="button" className="btn-submit" onClick={conectar}>{account ? 'Disconnect Wallet' : 'Connect Wallet'}</button>
              <p className='text-white mt-2'>{t("title.Connect your EVU wallet to Evulus Staking")}</p>
             </>):(
              
              <div className="panel">
                <div class="row">

                  <div className="panel__cuenta d-flex col-md-10">
                    <select className="data-value custom-select form-select-lg mb-2"
                     
                   
                        onChange={handleChange}>
                      
                         {!listacount[0] ? (<option selected> {t("Staked.Select Account")}</option>):(null)} 
                      { listacount.map((row, key) => (
                       selecionar == key ? (row[0] > 0 ? (<option selected key={'p'+key}   value={key}>{t("Staked.Account")} {key} - {t("Staked.Amount")} : {FMnunber(row[0])}</option>):(null)):(row[0] > 0 ? (<option  key={'p'+key}   value={key}>{t("Staked.Account")} {key} - {t("Staked.Amount")} : {FMnunber(row[0])}</option>):(null))
                        
                      ))}
                
                  </select>
                    <div className="data-value">
                   
                        <input className='p-1' autoFocus  id="myAnchor"
                              type='number'
                              value={inputstake}
                              placeholder={t("Staked.Amount")}
                              onChange={handleInputChange}/>
                              <button className="value text-black" onClick={maxBalance}><strong>Max</strong></button>
                              
                    </div>
                  
                </div>

                  <div className="panel__day">
                    <div className="component-day">
                        <div className="day-mes">
                            <div className="day-mes__title">{t("Staked.Days")}</div>
                            <div className="day-mes__items row moveboton">
                              
                                <button  style={myStyle} className='btn-evulus-small col-md-3 col-sm-3 p-0 m-0' onClick={e => setSlidervalue(90)}>{t("Staked.3 Month")}</button>
                                <button style={myStyle} className='btn-evulus-small col-md-3 col-sm-3 p-0 m-0' onClick={e => setSlidervalue(180)}>{t("Staked.6 Months")}</button>
                                <button style={myStyle} className='btn-evulus-small col-md-3 col-sm-3 p-0 m-0' onClick={e => setSlidervalue(270)}>{t("Staked.9 Months")}</button>
                                <button style={myStyle} className='btn-evulus-small col-md-3 col-sm-3 p-0 m-0' onClick={e => setSlidervalue(360)}>{t("Staked.12 Months")}</button>
                            </div>
                        </div>
                        <div className="slider-range">
                            <div className="prev" onClick={prevb}></div>
                            <div className="porcentaje">{slidervalue}</div>
                            <div className="slider">
                                <input className="range" type="range" min="1" max="360" 
                                value={slidervalue}
                                onChange={e => setSlidervalue(e.target.value)}
                                step={1}></input>
                            </div>
                            <div className="next" onClick={nextb}></div>
                        </div>
                    </div>
                </div>
                  <PanelMes apy={evuapy} days={slidervalue}/>
                   <PanelButton checkaprove={checkaprove} mybalance={mybalance} mystake={mystake} stakestado={stakestado} stakeStart={stakeStart} unStaking={unStaking} reStaking={reStaking} ClaimReward={ClaimReward} approveStart={approveStart} unstake={unstake} myreward={myreward} />   
                  <PanelBalance mybalance={FMnunber(mybalance)} rewards={FMnunber(myreward)} stake={FMnunber(mystake)}/>
                  <PanelTable rows={listacount} totalRewards={totalRewards}/>
                </div>
              </div>)}

            </div>

              
                           
        </section>
      
      </main>

    );
     
}
