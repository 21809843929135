////////////CONVERTIR WEI CON DOS DECIMALES//////////
const FMnunber = (number) =>{
    return (number / 1000000000).toFixed(2);
  }
////////CALCULAR DIAS/////////////////////
export const getDias = (datanum) =>{
  let dias = Math.round(((datanum*1000) - Date.now()) / 86400000)  + ' Days'
  dias = parseInt(dias) 
  if(dias < 1){
    return 0;
  }else{
    return dias;
  } 
}
export const getHoras = (datanum) =>{
  let h = Math.round(((datanum*1000) - Date.now()) / 3600000)
  let m = Math.round(((datanum*1000) - Date.now()) / 60000)
  h = parseInt(h) 
  m = parseInt(m) 
   if(h <= 0)
      return 0;

  if(h <= 1){
    return m + ' Minutes'
  }
  return h + ' hours'
}
////////////CAlCULO DE GANANCIAS MENSUALES
export const getPorcentMes = (qdays,amount,apy) => {
  let Meses = [2,3,4,6,7,8,9,10,11,12,14,14];
  let qmes = Math.ceil(qdays/30) ;
  let pmes = 0;
  let _amount = amount / 100;
  let result = 0;
  let _dias = 0;
  let _mdias = 0
  let mdia = qdays;
  let rdias = 0;
  for(let i = 1; i<= qmes; i++){   
    _dias = i * 30;
    _mdias= _dias - 30;
    let percent = 0;
  if(qdays >= 14){
      if(i<2){ 
        if(mdia >= 30)
           mdia = 30
        pmes = Math.ceil(mdia/30)   
        percent = (apy / 100) * Meses[pmes-1];
        result  += ((_amount  * percent) / 30) * mdia 
        console.log("Resul: "+result+" mes: "+Meses[pmes-1]+" percent:"+percent)
      }else{
        pmes = Math.ceil(_mdias / 29) 
        if(qdays < (pmes*30)){
          console.log("soy menor")
          rdias = qdays - ((pmes-1)*30);
        }else{
          rdias = 30
        } 
        percent = (apy / 100) * Meses[pmes-1];
        result +=   ((_amount  * percent) / 30) * rdias
        console.log("Resul60dias: "+result+" mes: "+Meses[pmes-1]+" percent:"+rdias)
      }
  }

  }
  return(result)
}


  export default FMnunber;